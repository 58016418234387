.form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    background-color: #FEEFD7;
}

.dropdown-title{
    font-weight: bold;
}

.bio-field{
    background-color: #FEEFD7;
    width: 100%;
}

.inputField {
    background-color: #FEEFD7; /* This is a soft yellow color */
    border: 1px solid white; /* A border that complements the soft yellow */
    padding: 8px 12px; /* Some padding for better appearance */
    margin-bottom: 10px; /* Space between input fields */
    border-radius: 4px; /* Rounded corners */
}

.remove-hairstyle-Button{
    background-color: red;
    border-color: white;
    color: white;
}

.add-hairstyle-Button{
width: 20%;
justify-content: center;
align-items: center;
background-color: #FEEFD7;
color: black;
border-color: white;
}

.hairstyle-submit-button{
    width: 50%;
    background-color: black;
    color: #FEEFD7;
    border-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


#profForm {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Provides spacing between form elements */
    background-color: white;
    padding: 20px 40px;
    border-radius: 50px;
    width: 50vw;
    margin-bottom: 100px;
    margin-left: 100px;
    margin-right: 100px;
}


.input{
display: flex;
flex-direction: column;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
    gap: 10px; /* Adds some spacing between the images */
}

.imageWrapper {
    position: relative; /* So that the X button can be positioned on the top-right of the image */
}

.deleteButton {
    background-color: rgba(255, 0, 0, 1); /* Translucent red */
    color: white;
    cursor: pointer;
    border: none;
    width: 25px;    /* Set width and height to make it a circle */
    height: 25px;
    border-radius: 50%; /* Makes the button circular */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; /* Adjust size of the "X" text as needed */
    transition: background-color 0.3s; /* Optional: Smooth transition for hover effect */
}

.deleteButton:hover {
    background-color: rgba(255, 0, 0, 0.7); /* Darker translucent red on hover */
}

.customFileUpload {
    padding: 10px 15px;
    background-color: #4CAF50; /* Change color as desired */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s;

    /* Optional hover effect */
    &:hover {
        background-color: #45a049; /* Slightly darker green */
    }
}

@media only screen and (max-width: 480px) {

    .form-container h1{
        font-size: x-large;
    }
    
    #profForm {
        display: flex;
        flex-direction: column;
        gap: 1rem; /* Provides spacing between form elements */
        background-color: white;
        padding: 20px 40px;
        border-radius: 50px;
        width: 90vw;
        margin-bottom: 10px;
    }

    #profForm input{
        font-size: large;
    }

    .add-hairstyle-Button{
        width: 50%;
    }

  }
