    .image-section {
    flex: 1;
  }
  
  .left-image {
    width: 100%;
    height: 100%;
  }
  
  .form-section {
    flex: 1;
    padding: 50px;
    
  }

  
/* ... (other styles remain unchanged) ... */

.login-container {
  display: flex;
  height: 100vh; /* Vertically center */
}

.image-section {
  flex: 1;
}

.form-section {
  flex: 1;
  height: 100vh;
  padding: 50px;
  display: flex;  /* Turn this into a flex container */
  flex-direction: column;  /* Stack children vertically */
  justify-content: center;  /* Horizontally center the children */
  align-items: center;  /* Vertically center the children */
  background-color:#FEEFD7;
}

/* ... (other styles remain unchanged) ... */


  .input-group {
    margin-bottom: 20px;
    width: 50%;
  }
  
  .email-input, .password-input {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .checkbox-forgot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .remember-me {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    max-width: 30%;
  }
  
  .remember-me input[type="checkbox"] {
    margin: 0;  /* Remove all margins */
    padding: 0;  /* Remove all padding */ /* Space between the checkbox and its label */
  }
  
  /* ... (other styles remain unchanged) ... */

.sign-in-btn {
    width: 50%;  /* Reduced width */
    height: 50px;  /* Increased height */
    margin: 10px auto;  /* Center the button and add some spacing */
    background-color: black;  /* Example blue color, you can change this */
    color: #FEEFD7;  /* White text */
    border: none;  /* Remove default borders */
    border-radius: 5px;  /* Slight radius */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  /* Drop shadow */
    font-size: 16px;  /* Slightly larger font */
    cursor: pointer;  /* Hand cursor on hover */
    transition: transform 0.1s, box-shadow 0.1s;  /* Smooth transition for button press effect */
  }

  .social-btn {
    width: 50%;  /* Reduced width */
    height: 50px;  /* Increased height */
    margin: 10px auto;  /* Center the button and add some spacing */
    background-color:  #F48144;  /* Example blue color, you can change this */
    color: white;  /* White text */
    border: none;  /* Remove default borders */
    border-radius: 5px;  /* Slight radius */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  /* Drop shadow */
    font-size: 16px;  /* Slightly larger font */
    cursor: pointer;  /* Hand cursor on hover */
    transition: transform 0.1s, box-shadow 0.1s;  /* Smooth transition for button press effect */
  }
  
  .sign-in-btn:hover, .social-btn:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);  /* Slightly darker shadow on hover */
  }
  
  .sign-in-btn:active, .social-btn:active {
    transform: translateY(2px);  /* Button press effect */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);  /* Reduced shadow when pressed */
  }
  
  .forgot-password {
    /* ... (existing styles) ... */
    display: inline-block; 
    margin-left: 20px;
    color: #007BFF;  /* Color to match the buttons, making it look like a link */
  }
  
  /* ... (other styles remain unchanged) ... */
  
  .separator {
    text-align: center;
    margin-bottom: 20px;
    display: flex;  /* Make this a flex container to better control spacing */
    align-items: center;  /* Vertically align the content */
    justify-content: center;  /* Horizontally align the content */
    width: 100%;
}

.signUpHeader{
    display: inline-flex;
    font-size: large;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.separator-line {
    display: inline-block;
    width: 40%;
    height: 1px;  /* Adjust the height for thinner lines */
    background-color: black;  /* Color of the line */
    margin: 0 10px;  /* Add a gap on either side */
}

.x-mark {
    width: 18px;
    height: 18px;
    position: relative;
  }
  
  .x-mark::before,
  .x-mark::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: black;
  }
  
  .x-mark::before {
    transform: rotate(45deg);
  }
  
  .x-mark::after {
    transform: rotate(-45deg);
  }

  @media only screen and (max-width: 480px) {
    .login-container {
      display: flex;
      flex-direction: column;
      background-color:#FEEFD7;
      width: 100vw;
    }

    .image-section{
      display: block;
      margin: auto;
      max-width: 50%; /* to make image responsive */
      height: auto;
    }

    .input-group{
      width: 75vw;
    }

    .sign-in-btn{
      width: 75vw;
    }

    .social-btn{
      width: 75vw;
    }

    
  }
  
  