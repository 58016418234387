.imageRows h1{
  text-align: center;
}

.Title{
  margin-top: 20px;
  width: 100vw;
  background-color: white;
  font-size: 40px;
  color: black;
  font-weight: bold;
  text-align: center;
  padding: 40px;
}

.imageRows > .imageRow:nth-of-type(odd) {
  background-color: #FEEFD7;
  justify-content:space-between;
}

.imageRows .imageRow:nth-of-type(odd) h1{
  color: #FEEFD7;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
}

/* Or, starting from the second one */
.imageRows > .imageRow:nth-of-type(even) {
  background-color: black;
  justify-content: space-between;
  flex-direction: row-reverse;
  color: #FEEFD7;
}

.imageRows .imageRow:nth-of-type(even) h1{
  color: black;
  border-radius: 10px;
  background-color: #FEEFD7;
  padding: 10px;
  justify-content: space-between;
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {
  .imageRows .imageRow:nth-of-type(odd) h1{
    padding: 2.5px;
    justify-content:
  }
  .imageRows .imageRow:nth-of-type(even) h1{
    padding: 2.5px;
  }
}
