* {
    box-sizing: border-box;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }
  
  .header {
    background-color: white;
    color: white;
    text-align: center;
    padding: 1em;
  }
  
  .title {
    margin: 0;
    animation: fadeIn 2s ease-in;
  }
  
  .intro {
    text-align: center;
    padding: 2em;
    background-color: white;
    color: black;
  }
  
  .tagline {
    font-size: 1.5em;
    animation: fadeIn 2s ease-in;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make sure it fills at least the whole height of the viewport */
  }
  
  .header{
    height:auto;
  }
  
  .footer {
    /* Height of header and footer elements (adjust as needed) */
    height: 60px;
  }
  
  .intro {
    flex-grow: 1; /* Take up remaining space */
    min-height: calc(100vh - 60px - 60px - 75px - 600px); /* 100% of viewport height minus the heights of header and footer */
  }
  
  
  .selector {
    display: flex;
    justify-content: center;
    border: 2px solid black;
    border-radius: 20px;
    overflow: hidden;
    width: 25vw; /* Add or adjust this line to control the overall width */
    height: 30px;
    margin: 0 auto;
  }
  
  .selector-item {
    padding: 10px;
    flex: 1; /* Evenly spaced */
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .selector-item.selected {
    background-color: black;
    color: white;
  }
  
  .output {
    margin-top: 20px;
  }
  
  .image-resize {
    width: 400px;
    height: auto;
  }
  
  .rectangle-container {
    display: flex;
    justify-content: space-between;
    background: white;
    margin: 0px 0;
    width: 100vw;
    height: auto;
  }
  
  .rounded-rectangle {
    flex: 1;
    margin: 0 100px;
    background-color: #FEEFD7;/* Opaque black background */
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .image-separator{
    background-color: white;
    text-align: center;
    font-size: 18px;
    color: #F48144;
    padding: 20px;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    padding: 10px;
    text-shadow: 2px 2px 4px #000000;
  }
  
  .rectangle-text {
    color: black;
    text-align: center;
  }
  
  .shape-square {
    width: 30px;
    height: 30px;
    background-color: red;
    margin-bottom: 10px;
  }
  
  .features {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #F48144;
  }
  
  .feature-block{
    margin: 10px;
  }
  
  .features-animation {
    animation: slideIn 2s ease;
  }
  
  .footer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 1em;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .header, .footer{
      height: auto;
      width: 100vw;
    }
  
    .image-resize{
      width: 50vw;
      height: auto;
    }
  
    .image-separator h1{
      font-size: 24px;
    }
  
    .features{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100vw;
    }
    .rectangle-container {
      flex-direction: column;
      height: auto;
      width: auto;
    }
  
    .selector{
      width: auto;
    }
  
    .rounded-rectangle{
      height: auto;
      width: auto;
      margin: 10px;
      font-size: 10px;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
  
    .content {
      flex-direction: column;
    }
  }
  