/* Profile.css */

.profile-page-body {
    background-color: #FEEFD7;
}

.profile-page {
    padding: 2em;
    max-width: 1200px;
    margin: auto;
    background-color: #FEEFD7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-page h1 {
    text-align: center;
    justify-content: center;
    font-size: 2.5em;
    margin-bottom: 0.5em;
    color: #333;
}

/* Container for buttons to give space in between */
.profile-nav-buttons {
    display: flex;
    justify-content: space-between; /* Put maximum space between buttons */
    width: 25%; /* Adjust as necessary */
    margin: 20px 0; /* Add some vertical space around the button group */
  }
  
  /* Generic button styling */
  .profile-nav-buttons button {
    padding: 10px 20px; /* Adjust as necessary */
    font-size: 16px; /* Adjust as necessary */
    border: none;
    border-radius: 5px; /* Adjust as necessary */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effects */
  }
  
  /* Sign Out button - Bold and Eye-Catching using Black and Orange */
  .profile-nav-buttons button:first-child {
    background-color:  #F48144;
    color: white;
    font-weight: bold; /* Make text bold */
  }
  
  /* Edit Profile button - Bold and Eye-Catching using White and Brown */
  .profile-nav-buttons button:last-child {
    background-color:  #FEEFD7;
    color: black;
    font-weight: bold; /* Make text bold */
  }
  

.profile-page img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-page .info,
.profile-page .hairstyles,
.profile-page .gallery {
    margin-top: 2em;
    border-top: 2px solid #e0e0e0;
    padding-top: 2em;
}

.profile-page .info p,
.profile-page .hairstyles div p {
    color: black;
    margin-bottom: 0.5em;
}

.profile-page .hairstyles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1em;
}

.profile-page a {
    color: black;
    background-color: white;
}

.profile-page .hairstyles div {
    background-color: #fff;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-page .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1em;
}

.info-row {
    display: flex;
    flex-wrap: wrap; 
    margin-bottom: 2.5em; /* Adjust the value to get the desired spacing */
}

.info-row:not(:first-child) {
    margin-top: 2.5em;  /* Adjust the value to get the desired spacing */
}

.info-value {
    background-color: white;
    font-weight: bold !important;
    padding: 1em;
    border-radius: 8px;
    margin-left: 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.hairstyle-item {
    background-color: white !important;
    font-weight: bold !important;
    padding: 1em;  /* Optional: Adds some padding around the text */
    border-radius: 8px;  /* Optional: Rounds the corners of the div */
}


.profile-page .gallery img {
    max-width: 100%;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
}

.profile-page .gallery img:hover {
    transform: scale(1.05);
}

.center-container {
    display: flex;
    flex-direction: column;  /* Items will be arranged in a column */
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 480px) {

    .profile-nav-buttons{
        justify-content: center;
        width: 80%;
    }

    .info-row {
        align-items: flex-start;
      }
    
      .info-row strong {
        margin-bottom: 0.25em; /* Add space between the label and the value on small screens */
      }
      
      .info-value{
        margin-bottom: 1em;
      }
  }
