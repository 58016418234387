.profSignUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FEEFD7;
    color: black;
    padding: 20px;
    width: 25vw;
    height: 175px;
    border-radius: 20px;
}

.signUpText {
    padding-bottom: 10px;
}

.ProfSignUp a:link, a:visited, .signup-button {
    height: 35px;
    background-color: black;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    padding: 8px 10px;
    color: rgba(240, 230, 220, 1);
    border: 1px solid;
    font-family: Arial;
    font-weight: bold;

}

.ProfSignUp a:hover, a:active, .signup-button:hover {
    background-color: #F48144;
    color: white;
}

@media only screen and (max-width: 480px) {
  .profSignUp{
    width: 95vw;
  }
}
