.imageRow img{
  width: auto;
  height: 400px;
  border-radius: 10px;
}

h3{
  display: flex;
  font-size: 48px;
  justify-content: center;
  align-items: center;
}

.imageRow{
  margin: 40px;
  max-width: 100vw;
  display: flex;
  border-radius: 20px;
}

.imageRowDescription{
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 480px) {
  .imageRow{
    margin: 10px;
  }

  .imageRow img{
    height: 25vh;
  }

  h3{
    font-size: 18px;
    text-align: center;
  }
}
