input {
    height: 35px;
    width: 180px;
    margin-right: 5px;
    border-radius: 5px;
    padding-left: 5px;
    border: 0px solid;
}
input:focus{
  outline: none;
  box-shadow: none;
  border: 1px solid;/* Be cautious with this, as it can affect accessibility */
}

.newsletter{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  width: 25vw;
  height: 175px;
  border: 2px solid white;
  border-radius: 20px;
}

button {
  height: 35px;
  background-color: white;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: black;
  border: 1px solid;
  font-family: Arial;
  font-weight: bold;
}


@media only screen and (max-width: 480px) {
    h1 {
        margin: 10px;
        font-size: 14px;
    }
    .newsletter {
        margin-bottom: 0px;
        width: 95vw;
    }
}
