.list-container {
    height: 100%;
    display: flex;
    flex-direction: row; /* Display items in a row */
    gap: 10px; /* Horizontal spacing between items */
    flex-wrap: wrap; /* Allow wrapping if there are too many items */
}

.list-item {
    padding: 10px 15px;
    border-radius: 10px; /* Rounded corners */
    background-color: #FEEFD7;
    cursor: pointer;
    transition: background-color 0.3s;
}

.list-item.selected {
    background-color:  #F48144;
}
