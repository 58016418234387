.profile-picture {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    /* border: 2px solid #FEEFD7; */
    cursor: pointer;
    overflow: hidden;
    background-color: #f4f4f4;
    margin-bottom: 20px;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
}

.overlay i {
    font-size: 50px;
    margin-bottom: 10px;
}
